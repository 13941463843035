export const getLogoByDomain = () => {
    const domainLogoMapping = {
        'onraplay.com': '/other/logo.png',
        'dream444.in': '/other/dream444.png',
        'dream2025.in': '/other/dream2025.png',
        'jmt777.com': '/other/jmt777.png',
        'dreamn444.com': '/other/dreamn444.png',
        'rajexch.in': '/other/rajexch.png',
    };

    // Get the current domain
    const currentDomain = window.location.hostname;

    // Return the corresponding logo or a default one
    return domainLogoMapping[currentDomain] || '/other/logo.png';
};
