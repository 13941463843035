// Define the configuration object
const config = {
    apiUrl: 'https://digital.allcasino.in:3000/',
    // apiUrl: 'http://localhost:3000/',

    radisUrl: 'https://85.159.214.160:9092/',
    bUrl: 'http://139.162.213.154:3000/',
    
    userTypes: ['Super Admin', 'Admin', 'Super Master', 'Master', 'Agent', 'User'],
    superAdmin: '66d1b9f840b85df9e897c05d',
    userTypesAlias: {
        'super_admin': 'Super Admin',
        'admin': 'Admin',
        'super_master': 'Super Master',
        'master': 'Master',
        'agent': 'Agent',
        'user': 'User'
    },

    // Dynamically assigned site code
    sitecodes: 'P26',

    videoUrl: {
        '7ud': 'https://tv.fireflybet.in/index.html?id=3058',
        'ab': 'https://route53.casinovid.in/dvideo/andarbahar/',
        'ltp': 'https://backlayexchange.com/new/oneday/',
        't20': 'https://backlayexchange.com/new/2020/',
        'dt20': 'https://tv.fireflybet.in/index.html?id=3059/',
        'aaa': 'https://shroute.casinovid.in/diamondvideo/dot.php?id=3056',
        '32c': 'https://shroute.casinovid.in/diamondvideo/dot.php?id=3034',
    },

    marketSarket: "http://marketsarket.in:3002/",

    casino_type_id: {
        '7ud': '1001',
        'ab': '1002',
        'ltp': '1003',
        't20': '1004',
        'dt20': '1005',
        '32c': '1007',
        'Lucky7A': '1008',
        '1daytp': '1009',
        'baccarat': '1010',
        'poker20A': '1011',
        'dt': '1012',
        '1daydt': '1013',
        'baccaratcard29': '1015',
        'mulfisteenpatti': '1016',
        'dtlteenpatti': '1017',
        'baccarat-A': '1018',
        'baccarat-C': '1019',
        'fdt': '1020',
        'fl7': '1021',
        'Lucky7B': '1022',
        '3cardJ': '1023',
        'casinowar': '1024',
        'instantworli': '1025',
        'bollywoodcasino': '1026',
        'imd': '1027',
        'aaa': '1028',
        'dtlA': '1029',
        'lottery': '1030',
        '20-20-race': '1031',
        'one-card-meter': '1032',
        '1-card-20-20': '1033',
        'trio': '1034',
        'kbc': '1035',
        'casino-meter': '1036',
        'point-tp': '1037',
        'joker-tp': '1038',
    },

    casino_video_id: {
        't20': 'GAME10',
        '1daytp': 'GAME13',
        'dt': 'GAME18',
        'dt20': 'GAME19',
        'Lucky7A': 'GAME30',
        'baccarat': 'GAME2',
        'baccarat-A': 'GAME2',
        'baccarat-C': 'GAME2',
        'poker20A': 'GAME7',
        '32c': 'GAME22',
        'dtlteenpatti': 'GAME4',
        'bollywoodcasino': 'GAME50',
    },

    casino_event_id: {
        '7ud': '98789',
        'ab': '87564',
        'ltp': '56767',
        't20': '56768',
        'dt20': '98790',
        'aaa': '98791',
        '32c': '56967'
    },

    site_url: 'https://galaxyexch.co/'
};


// Define site codes and superAdmin for different domains
const domainConfig = {
    'onraplay.com': { sitecodes: 'P26', superAdmin: '66d1b9f840b85df9e897c05d' },
    'dream444.in': { sitecodes: 'P35', superAdmin: '67d18e9e32961547e8a94ba5' },
    'dream2025.in': { sitecodes: 'P39', superAdmin: '67d809ec32961547e8a94c14' },
    'jmt777.com': { sitecodes: 'P31', superAdmin: '67b4ab8a6e3e1d68dcba3767' },
    'dreamn444.com': { sitecodes: 'P37', superAdmin: '67d2e47b32961547e8a94bef' },
    'rajexch.in': { sitecodes: 'P42', superAdmin: '67dd74d732961547e8a94cc2' }
};

// Get the current hostname
const currentDomain = window.location.hostname;

// Assign the site code and superAdmin dynamically based on the domain
if (domainConfig[currentDomain]) {
    config.sitecodes = domainConfig[currentDomain].sitecodes;
    config.superAdmin = domainConfig[currentDomain].superAdmin;
}

// Export the entire config object
export default config;

// Export other constants separately
export const SOCKET_URL = 'wss://ws.operator.buzz:7072/';
export const DEFAULT_CURRENCY = 'PBU';
export const SITE_URL = 'https://galaxyexch.co/';
