import '../../../App.css'
import { useEffect, useState } from 'react';
import Teenpatti2020 from '../Teenpatti2020';
import Vimaan from '../Vimaan';
import Baccarat from '../Baccarat';
import Evolution from '../Evolution';
import Dt2020 from '../Dt2020';
import Lucky7a from '../Lucky7a';
import AndarBaharA from '../AndarBaharA';
import Cards32A from '../Cards32A';
import Ezugi from '../Ezugi';
import Poker2020A from '../Poker2020A';
import Teenpatti1Day from '../Teenpatti1Day';
import DragonTiger from '../DragonTiger';
import FastLucky7 from '../FastLucky7';
import DT1day from '../DT1day';
import MuflisTp from '../MuflisTp';
import DtlA from '../DtlA';
import FastDt from '../FastDt';
import CasinoWar from '../CasinoWar';
import Baccarat29Card from '../Baccarat29Card';
import InstantWorli from '../InstantWorli';
import PointTp from '../PointTp';
import JokerTp from '../JokerTp';
import AmarAkbarAnthony from '../AmarAkbarAnthony';
import CardMeter1 from '../CardMeter1';
import Card2020 from '../Card2020';
import Trio from '../Trio';
import Lottery from '../Lottery';


const OpenRules = ({ open }) => {
    const [activeGame, setActiveGame] = useState(null);

    const games = [
        "20-20 TEENPATTI",
        "VIMAAN",
        "BACCARAT",
        "EVOLUTION",
        "20-20 DRAGON TIGER",
        "LUCKY 7 - A",
        "ANDAR BAHAR - A",
        "32 CARDS - A",
        "EZUGI",
        "20-20 POKER - A",
        "BACCARAT - A",
        "1DAY TEEN PATTI",
        "DRAGON TIGER",
        "FAST LUCKY - 7",
        "1 DAY DRAGON TIGER",
        "MUFLIS TEEN PATTI",
        "DTL - A",
        "FAST DRAGON TIGER",
        "CASINO WAR",
        "CASINO METER",
        "29 CARD BACCARAT",
        "INSTANT WORLI",
        "POINT TEEN PATTI",
        "JOKER TEEN PATTI",
        "HEADS & TAILS",
        "DREAM CATCHER",
        "LUCKY 0 TO 9",
        "BETGAMES CASINO",
        "TVBET",
        "AMAR AKBAR ANTHONY",
        "3 CARD JUDGEMENT - A",
        "1 CARD METER",
        "1 CARD 20-20",
        "TRIO",
        "ANDAR BAHAR - C",
        "LOTTERY",
        "20-20 POKER - B",
        "DTL TEENPATTI",
        "INA MINA DIKA",
        "LUCKY 7 - B",
        "BOLLYWOOD CASINO",
        "20-20 CARD RACE",
        "BACCARAT - C",
        "32 CARDS - B",
        "KBC"
    ];

    const toggleGame = (game) => {
        setActiveGame(activeGame === game ? null : game);
    };

    return (
        <div className='bg-white  relative '>
            <div className="max-w-md mx-auto  text-white rounded-lg p-4">
                <div className="mt-4 space-y-3">
                    {games.map((game, index) => (
                        <div key={index} className="border border-black-400 rounded-lg">
                            <div
                                className="bg-[linear-gradient(-180deg,#010101_0,_#0e0e0e_100%)] text-white-400 px-4 py-2 font-semibold cursor-pointer hover:bg-[linear-gradient(-180deg,_#f6a21e_0,_#cb7e05_100%)] hover:text-black transition"
                                onClick={() => toggleGame(game)}
                            >
                                {game}
                            </div>
                            {activeGame === game && (
                                <div className="rulesCasino bg-white  text-black px-4 py-3">
                                    {
                                        game === "20-20 TEENPATTI" ? (
                                            <>
                                                <Teenpatti2020 />
                                            </>
                                        ) : game === "VIMAAN" ?
                                            (
                                                <>
                                                    <Vimaan />
                                                </>
                                            )
                                            : (game === "BACCARAT" || game === "BACCARAT - A" || game === "BACCARAT - C" || game === "CASINO METER") ?
                                                (
                                                    <>
                                                        <Baccarat />
                                                    </>
                                                )  : game === "EVOLUTION" ?
                                                (
                                                    <>
                                                        <Evolution />
                                                    </>
                                                ) : game === "20-20 DRAGON TIGER" ?
                                                (
                                                    <>
                                                       <Dt2020 />
                                                    </>
                                                ) : game === "LUCKY 7 - A" ?
                                                (
                                                    <>
                                                       <Lucky7a />
                                                    </>
                                                ) : game === "ANDAR BAHAR - A" ?
                                                (
                                                    <>
                                                       <AndarBaharA />
                                                    </>
                                                )  : (game === "32 CARDS - A" || game === "32 CARDS - B") ?
                                                (
                                                    <>
                                                       <Cards32A />
                                                    </>
                                                ) : game === "EZUGI" ?
                                                (
                                                    <>
                                                       <Ezugi />
                                                    </>
                                                ) : game === "20-20 POKER - A" ?
                                                (
                                                    <>
                                                       <Poker2020A />
                                                    </>
                                                ) : game === "1DAY TEEN PATTI" ?
                                                (
                                                    <>
                                                       <Teenpatti1Day />
                                                    </>
                                                ) : game === "DRAGON TIGER" ?
                                                (
                                                    <>
                                                       <DragonTiger />
                                                    </>
                                                ) : game === "FAST LUCKY - 7" ?
                                                (
                                                    <>
                                                       <FastLucky7 />
                                                    </>
                                                ) : game === "1 DAY DRAGON TIGER" ?
                                                (
                                                    <>
                                                       <DT1day />
                                                    </>
                                                ) : game === "MUFLIS TEEN PATTI" ?
                                                (
                                                    <>
                                                       <MuflisTp />
                                                    </>
                                                ) : game === "DTL - A" ?
                                                (
                                                    <>
                                                       <DtlA />
                                                    </>
                                                ) : game === "FAST DRAGON TIGER" ?
                                                (
                                                    <>
                                                       <FastDt />
                                                    </>
                                                ) : game === "CASINO WAR" ?
                                                (
                                                    <>
                                                       <CasinoWar />
                                                    </>
                                                )  : game === "29 CARD BACCARAT" ?
                                                (
                                                    <>
                                                       <Baccarat29Card />
                                                    </>
                                                ) : game === "INSTANT WORLI" ?
                                                (
                                                    <>
                                                       <InstantWorli />
                                                    </>
                                                )  : game === "POINT TEEN PATTI" ?
                                                (
                                                    <>
                                                       <PointTp />
                                                    </>
                                                ) : (game === "JOKER TEEN PATTI" || game === "HEADS & TAILS" || game === "DREAM CATCHER" || game === "LUCKY 0 TO 9" || game === "BETGAMES CASINO" || game === "TVBET" || game === "3 CARD JUDGEMENT - A" || game === "20-20 POKER - B" || game === "DTL TEENPATTI" || game === "INA MINA DIKA" || game === "LUCKY 7 - B" || game === "BOLLYWOOD CASINO"  || game === "20-20 CARD RACE" || game === "KBC") ?
                                                (
                                                    <>
                                                       <JokerTp />
                                                    </>
                                                ) : game === "AMAR AKBAR ANTHONY" ?
                                                (
                                                    <>
                                                       <AmarAkbarAnthony />
                                                    </>
                                                ) : ( game === "1 CARD METER") ?
                                                (
                                                    <>
                                                       <CardMeter1 />
                                                    </>
                                                ) : game === "1 CARD 20-20" ?
                                                (
                                                    <>
                                                       <Card2020 />
                                                    </>
                                                ) : game === "TRIO" ?
                                                (
                                                    <>
                                                       <Trio />
                                                    </>
                                                ) : game === "ANDAR BAHAR - C" ?
                                                (
                                                    <>
                                                       <AndarBaharA />
                                                    </>
                                                ) : game === "LOTTERY" ?
                                                (
                                                    <>
                                                       <Lottery />
                                                    </>
                                                )
                                                :
                                                null
                                    }
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )

}
export default OpenRules