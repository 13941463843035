import '../../App.css'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { signal } from "@preact/signals-react";
export const eventId = signal("");
function GreyhoundRacingSchedule() {

    const [selectedCountry, setSelectedCountry] = useState('ALL')
    const [events, setEvents] = useState([])
    // eventId.value = id;
    const countries = [
        {
            'title': 'ALL'
        },
        {
            'title': 'GB',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_152_3716)">
                        <path d="M32 0H0V24H32V0Z" fill="white" />
                        <path d="M32 9.6H17.601V0H14.4V9.6H0V14.4H14.4V24H17.601V14.4H32" fill="#D22027" />
                        <path d="M21.333 16H23.719L32 22.211V24M10.667 8H8.281L0 1.789V0L10.667 8ZM10.667 16H13.052L2.385 24H0L10.667 16ZM21.333 8H18.948L29.615 0H32L21.333 8Z" fill="#D22027" />
                        <path d="M0 16V21.316L7.089 16M32 16V21.316L24.911 16H32ZM0 8V2.683L7.089 8H0ZM32 8V2.683L24.911 8H32ZM13.333 24V16.684L3.578 24H13.333ZM18.667 24V16.684L28.423 24H18.667ZM13.333 0V7.317L3.578 0H13.333ZM18.667 0V7.317L28.423 0H18.667Z" fill="#18325B" />
                    </g>
                    <defs>
                        <clipPath id="clip0_152_3716">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
    ]

    const eventList = [
        {
            'title': 'TESTING 3',
            'eventTime': [
                '5:00'
            ],
            'country': 'IN'
        },
        {
            'title': 'TESTING 4',
            'eventTime': [
                '5:00'
            ],
            'country': 'IN'
        },
        {
            'title': 'Cheltenham ',
            'eventTime': [
                '23:25',
            ],
            'country': 'GB'
        },
        {
            'title': 'Newmarket',
            'eventTime': [
                '17:40', '18:15', '18:50', '19:25', '20:05', '20:40', '21:15', '21:45', '22:20'
            ],
            'country': 'GB'
        },
        {
            'title': 'Goodwood ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Uttoxeter ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Hexham ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '0:20', '0:55'
            ],
            'country': 'GB'
        },
        {
            'title': 'Thirsk ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Yarmouth  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Toowoomba ',
            'eventTime': [
                '16:18'
            ],
            'country': 'AUS'
        },
        {
            'title': 'Bordeaux Le Bouscat ',
            'eventTime': [
                '16:17'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Auteuil ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Angers ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Greyville ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'RSA'
        },
        {
            'title': 'Turffontein ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'RSA'
        },
        {
            'title': 'Punchestown  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '23:00'
            ],
            'country': 'IRE'
        },
        {
            'title': 'Churchill Downs  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05', '18:35',
            ],
            'country': 'USA'
        },
        {
            'title': 'Laurel Park ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'USA'
        },
        {
            'title': 'Tampa Bay Downs',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:25',
            ],
            'country': 'USA'
        },
        {
            'title': 'Philadelphia ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25',
            ],
            'country': 'USA'
        },
        {
            'title': 'Belmont At The Big A ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05',
            ],
            'country': 'USA'
        },
        {
            'title': 'Gulfstream Park ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05',
            ],
            'country': 'USA'
        },
        {
            'title': 'Woodbine  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'USA'
        },
        {
            'title': 'Hawthorne  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35',
            ],
            'country': 'USA'
        },
        {
            'title': 'Santa Anita Park',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25',
            ],
            'country': 'USA'
        },
    ]

    useEffect(() => (
        setEvents(eventList.filter(e => e.country == selectedCountry))
    ), [selectedCountry])
    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 '>
                <div className='col-span-12 bg-[#fff]  h-[fit-content]'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12'>
                            <div className='bg-[linear-gradient(-180deg,_#010101_0%,_#0e0e0e_100%)] text-[#ffffff] px-[8px] py-[10px]'>
                                <h5 className='p-0 m-0 text-[0.813rem] leading-[1.2] text-[#ffffff] font-bold'>Greyhound Racing Schedule</h5>
                            </div>
                        </div>
                        <div className='col-span-12'>
                            <ul className='bg-[#e2e2e2] border-b-[unset] [scrollbar-width:none] overflow-x-auto relative flex flex-nowrap pl-0 mb-0 [list-style:none] h-[45px] rounded-none'>
                                {
                                    countries.map((country, index) => (
                                        <li key={index}>
                                            <button type='button' className={`text-[#000000] !rounded-tl-[unset] !rounded-tr-[unset]  rounded-none text-[0.75rem] px-[22px] py-[3px] cursor-pointer w-[70px] h-[45px] border-[1px] border-[solid] border-[transparent] !items-center !justify-center !flex-col !flex [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${selectedCountry === country.title && 'bg-[#ffffff] border-l-[1px] border-l-[#c8ced3] border-r-[1px] border-r-[#c8ced3]'}  `} onClick={() => setSelectedCountry(country.title)}>
                                                {country.svg}
                                                <span className='font-bold text-[.75rem] text-[#000000]'>{country.title}</span>
                                            </button>
                                        </li>
                                    ))
                                }

                            </ul>
                            {selectedCountry === 'ALL' && (
                                <div className='mt-[-1px] !bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)] text-[#2789ce] border-[1px] border-[solid] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <table id="gameContainer" className='w-full mb-4 text-[#212529] align-top border-[#dee2e6] [caption-side:bottom] border-collapse'>
                                            <tbody className='text-[#212529]'>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Crayford</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px] text-[#7e97a7]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Nottingham </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Oxford  </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 03-05-2024 23:25 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Monmore </span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Romford </span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Doncaster </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 13:50 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Swindon  </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 14:10 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            )}
                            {selectedCountry !== 'ALL' && (
                                <div className='mt-[-1px] bg-[#ffffff] border-[1px] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <div className=''>
                                            <ul className='m-0 p-0'>
                                                {events.map((event, index) => (
                                                    <li key={index} className='w-full pt-[10px] px-[0] pb-[5px] border-b-[1px] border-b-[#ccc] [list-style:none] inline-block'>
                                                        <div className='flex'>
                                                            <span className='text-[0.813rem] font-bold min-w-[110px] inline-block text-[#000000] '>{event.title}</span>
                                                            <ul className='inline-block ml-[10px] p-0 mb-0 mt-0'>
                                                                {event.eventTime.map((time, inex) => (
                                                                    <li key={index} className='[list-style:none] inline-block'>
                                                                        <Link to={'#'}>
                                                                            <span className='cursor-pointer bg-[#e2e2e2] px-[15px] py-[5px] rounded-[2px] text-[.75rem] inline-table font-bold text-[#000000] min-w-[64px] ml-[5px] mb-[5px]'>{time}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >



            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative ' >
                <div className='col-span-12 bg-[#fff]  h-[fit-content]'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12'>
                            <div className='bg-[linear-gradient(-180deg,_#010101_0%,_#0e0e0e_100%)] text-[#ffffff] px-[8px] py-[10px]'>
                                <h5 className='p-0 m-0 text-[0.813rem] leading-[1.2] text-[#ffffff] font-bold'>Greyhound Racing Schedule</h5>
                            </div>
                        </div>
                        <div className='col-span-12'>
                            <ul className='bg-[#e2e2e2] border-b-[unset] [scrollbar-width:none] overflow-x-auto relative flex flex-nowrap pl-0 mb-0 [list-style:none] h-[45px] rounded-none'>
                                {
                                    countries.map((country, index) => (
                                        <li key={index}>
                                            <button type='button' className={`text-[#000000] !rounded-tl-[unset] !rounded-tr-[unset]  rounded-none text-[0.75rem] px-[22px] py-[3px] cursor-pointer w-[70px] h-[45px] border-[1px] border-[solid] border-[transparent] !items-center !justify-center !flex-col !flex [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${selectedCountry === country.title && 'bg-[#ffffff] border-l-[1px] border-l-[#c8ced3] border-r-[1px] border-r-[#c8ced3]'}  `} onClick={() => setSelectedCountry(country.title)}>
                                                {country.svg}
                                                <span className='font-bold text-[.75rem] text-[#000000]'>{country.title}</span>
                                            </button>
                                        </li>
                                    ))
                                }

                            </ul>
                            {selectedCountry === 'ALL' && (
                                <div className='mt-[-1px] !bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)] text-[#2789ce] border-[1px] border-[solid] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <table id="gameContainer" className='w-full mb-4 text-[#212529] align-top border-[#dee2e6] [caption-side:bottom] border-collapse'>
                                            <tbody className='text-[#212529]'>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 3</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px] text-[#7e97a7]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 4</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Cheltenham </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 03-05-2024 23:25 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Bordeaux Le Bouscat</span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Toowoomba </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 13:50 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 14:10 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            )}
                            {selectedCountry !== 'ALL' && (
                                <div className='mt-[-1px] bg-[#ffffff] border-[1px] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <div className=''>
                                            <ul className='m-0 p-0'>
                                                {events.map((event, index) => (
                                                    <li key={index} className='w-full pt-[10px] px-[0] pb-[5px] border-b-[1px] border-b-[#ccc] [list-style:none] inline-block'>
                                                        <div className='flex'>
                                                            <span className='text-[0.813rem] font-bold min-w-[110px] inline-block text-[#000000] '>{event.title}</span>
                                                            <ul className='inline-block ml-[10px] p-0 mb-0 mt-0'>
                                                                {event.eventTime.map((time, inex) => (
                                                                    <li key={index} className='[list-style:none] inline-block'>
                                                                        <Link to={'#'}>
                                                                            <span className='cursor-pointer bg-[#e2e2e2] px-[15px] py-[5px] rounded-[2px] text-[.75rem] inline-table font-bold text-[#000000] min-w-[64px] ml-[5px] mb-[5px]'>{time}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default GreyhoundRacingSchedule;
