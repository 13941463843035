import '../../App.css'
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { signal } from "@preact/signals-react";
export const eventId = signal("");
function HorseRacingSchedule() {

    const { id } = useParams();
    const [selectedCountry, setSelectedCountry] = useState('ALL')
    const [events, setEvents] = useState([])
    // eventId.value = id;
    const countries = [
        {
            'title': 'ALL'
        },
        {
            'title': 'IN',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.02 0.0500031H0V24H32.02V0.0500031Z" fill="#F89939" />
                    <path d="M32.02 8.033H0V16.016H32.02V8.033Z" fill="white" />
                    <path d="M32.02 16.017H0V24H32.02V16.017Z" fill="#1E8943" />
                    <path d="M16.01 15.219C17.5824 15.219 18.857 13.789 18.857 12.025C18.857 10.261 17.5824 8.83101 16.01 8.83101C14.4377 8.83101 13.163 10.261 13.163 12.025C13.163 13.789 14.4377 15.219 16.01 15.219Z" fill="#2A2C7A" />
                    <path d="M16.01 14.819C17.3852 14.819 18.5 13.5681 18.5 12.025C18.5 10.4819 17.3852 9.231 16.01 9.231C14.6348 9.231 13.52 10.4819 13.52 12.025C13.52 13.5681 14.6348 14.819 16.01 14.819Z" fill="white" />
                    <path d="M16.01 12.584C16.2856 12.584 16.509 12.3337 16.509 12.025C16.509 11.7163 16.2856 11.466 16.01 11.466C15.7344 11.466 15.511 11.7163 15.511 12.025C15.511 12.3337 15.7344 12.584 16.01 12.584Z" fill="#2A2C7A" />
                    <path d="M18.495 12.251L18.603 12.408L18.463 12.528L18.355 12.372L18.495 12.251Z" fill="#2A2C7A" />
                    <path d="M16.01 14.819L16.096 13.143L16.01 12.344L15.924 13.143L16.01 14.819Z" fill="#2A2C7A" />
                    <path d="M18.358 12.965L18.426 13.147L18.263 13.223L18.195 13.041L18.358 12.965Z" fill="#2A2C7A" />
                    <path d="M15.365 14.724L15.834 13.129L15.936 12.333L15.669 13.08L15.365 14.724Z" fill="#2A2C7A" />
                    <path d="M18.062 13.614L18.084 13.811L17.91 13.837L17.888 13.642L18.062 13.614Z" fill="#2A2C7A" />
                    <path d="M14.765 14.444L15.586 13.041L15.868 12.301L15.438 12.945L14.765 14.444Z" fill="#2A2C7A" />
                    <path d="M17.625 14.157L17.602 14.353L17.428 14.326L17.45 14.131L17.625 14.157Z" fill="#2A2C7A" />
                    <path d="M14.249 14.001L15.366 12.883L15.809 12.25L15.245 12.748L14.249 14.001Z" fill="#2A2C7A" />
                    <path d="M17.078 14.553L17.011 14.735L16.848 14.66L16.916 14.478L17.078 14.553Z" fill="#2A2C7A" />
                    <path d="M13.853 13.422L15.19 12.667L15.764 12.185L15.104 12.5L13.853 13.422Z" fill="#2A2C7A" />
                    <path d="M16.458 14.777L16.352 14.934L16.211 14.813L16.319 14.656L16.458 14.777Z" fill="#2A2C7A" />
                    <path d="M13.604 12.748L15.07 12.407L15.735 12.107L15.026 12.222L13.604 12.748Z" fill="#2A2C7A" />
                    <path d="M15.809 14.813L15.668 14.934L15.562 14.777L15.701 14.656L15.809 14.813Z" fill="#2A2C7A" />
                    <path d="M13.52 12.025L15.014 12.121L15.726 12.025L15.014 11.929L13.52 12.025Z" fill="#2A2C7A" />
                    <path d="M15.172 14.66L15.009 14.735L14.942 14.553L15.104 14.478L15.172 14.66Z" fill="#2A2C7A" />
                    <path d="M13.604 11.302L15.026 11.828L15.735 11.942L15.07 11.643L13.604 11.302Z" fill="#2A2C7A" />
                    <path d="M14.593 14.326L14.418 14.353L14.395 14.157L14.569 14.131L14.593 14.326Z" fill="#2A2C7A" />
                    <path d="M13.853 10.627L15.104 11.549L15.764 11.865L15.19 11.383L13.853 10.627Z" fill="#2A2C7A" />
                    <path d="M14.11 13.837L13.936 13.811L13.958 13.614L14.133 13.642L14.11 13.837Z" fill="#2A2C7A" />
                    <path d="M14.249 10.049L15.245 11.302L15.809 11.799L15.366 11.167L14.249 10.049Z" fill="#2A2C7A" />
                    <path d="M13.757 13.223L13.594 13.147L13.661 12.965L13.824 13.041L13.757 13.223Z" fill="#2A2C7A" />
                    <path d="M14.765 9.605L15.438 11.105L15.868 11.749L15.586 11.009L14.765 9.605Z" fill="#2A2C7A" />
                    <path d="M13.557 12.528L13.417 12.408L13.524 12.251L13.665 12.372L13.557 12.528Z" fill="#2A2C7A" />
                    <path d="M15.365 9.326L15.669 10.97L15.936 11.717L15.834 10.92L15.365 9.326Z" fill="#2A2C7A" />
                    <path d="M13.524 11.799L13.417 11.642L13.557 11.522L13.665 11.678L13.524 11.799Z" fill="#2A2C7A" />
                    <path d="M16.01 9.231L15.924 10.907L16.01 11.706L16.096 10.907L16.01 9.231Z" fill="#2A2C7A" />
                    <path d="M13.661 11.084L13.594 10.902L13.757 10.827L13.824 11.009L13.661 11.084Z" fill="#2A2C7A" />
                    <path d="M16.655 9.326L16.185 10.92L16.083 11.717L16.351 10.97L16.655 9.326Z" fill="#2A2C7A" />
                    <path d="M13.958 10.435L13.936 10.239L14.11 10.213L14.133 10.409L13.958 10.435Z" fill="#2A2C7A" />
                    <path d="M17.256 9.605L16.435 11.009L16.152 11.749L16.582 11.105L17.256 9.605Z" fill="#2A2C7A" />
                    <path d="M14.395 9.89301L14.418 9.69701L14.593 9.72301L14.569 9.91901L14.395 9.89301Z" fill="#2A2C7A" />
                    <path d="M17.771 10.049L16.654 11.167L16.211 11.799L16.775 11.302L17.771 10.049Z" fill="#2A2C7A" />
                    <path d="M14.942 9.49699L15.009 9.314L15.172 9.39L15.104 9.573L14.942 9.49699Z" fill="#2A2C7A" />
                    <path d="M18.167 10.627L16.83 11.383L16.256 11.865L16.916 11.549L18.167 10.627Z" fill="#2A2C7A" />
                    <path d="M15.562 9.273L15.668 9.116L15.809 9.236L15.701 9.394L15.562 9.273Z" fill="#2A2C7A" />
                    <path d="M18.415 11.302L16.95 11.643L16.285 11.942L16.995 11.828L18.415 11.302Z" fill="#2A2C7A" />
                    <path d="M16.211 9.236L16.352 9.116L16.458 9.273L16.319 9.394L16.211 9.236Z" fill="#2A2C7A" />
                    <path d="M18.5 12.025L17.006 11.929L16.294 12.025L17.006 12.121L18.5 12.025Z" fill="#2A2C7A" />
                    <path d="M16.848 9.39L17.011 9.314L17.078 9.49699L16.916 9.573L16.848 9.39Z" fill="#2A2C7A" />
                    <path d="M18.415 12.748L16.995 12.222L16.285 12.107L16.95 12.407L18.415 12.748Z" fill="#2A2C7A" />
                    <path d="M17.428 9.72301L17.602 9.69701L17.625 9.89301L17.45 9.91901L17.428 9.72301Z" fill="#2A2C7A" />
                    <path d="M18.167 13.422L16.916 12.5L16.256 12.185L16.83 12.667L18.167 13.422Z" fill="#2A2C7A" />
                    <path d="M17.91 10.213L18.084 10.239L18.062 10.435L17.888 10.409L17.91 10.213Z" fill="#2A2C7A" />
                    <path d="M17.771 14.001L16.775 12.748L16.211 12.25L16.654 12.883L17.771 14.001Z" fill="#2A2C7A" />
                    <path d="M18.263 10.827L18.426 10.902L18.358 11.084L18.195 11.009L18.263 10.827Z" fill="#2A2C7A" />
                    <path d="M17.256 14.444L16.582 12.945L16.152 12.301L16.435 13.041L17.256 14.444Z" fill="#2A2C7A" />
                    <path d="M18.463 11.522L18.603 11.642L18.495 11.799L18.355 11.678L18.463 11.522Z" fill="#2A2C7A" />
                    <path d="M16.655 14.724L16.351 13.08L16.083 12.333L16.185 13.129L16.655 14.724Z" fill="#2A2C7A" />
                </svg>
        },
        {
            'title': 'GB',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_152_3716)">
                        <path d="M32 0H0V24H32V0Z" fill="white" />
                        <path d="M32 9.6H17.601V0H14.4V9.6H0V14.4H14.4V24H17.601V14.4H32" fill="#D22027" />
                        <path d="M21.333 16H23.719L32 22.211V24M10.667 8H8.281L0 1.789V0L10.667 8ZM10.667 16H13.052L2.385 24H0L10.667 16ZM21.333 8H18.948L29.615 0H32L21.333 8Z" fill="#D22027" />
                        <path d="M0 16V21.316L7.089 16M32 16V21.316L24.911 16H32ZM0 8V2.683L7.089 8H0ZM32 8V2.683L24.911 8H32ZM13.333 24V16.684L3.578 24H13.333ZM18.667 24V16.684L28.423 24H18.667ZM13.333 0V7.317L3.578 0H13.333ZM18.667 0V7.317L28.423 0H18.667Z" fill="#18325B" />
                    </g>
                    <defs>
                        <clipPath id="clip0_152_3716">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            'title': 'AUS',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_152_3722)">
                        <path d="M0 0H32V24H0V0Z" fill="#2D317B" />
                        <path d="M8.00003 15.332L8.63003 17.218L10.552 16.516L9.41603 18.165L11.185 19.176L9.13503 19.349L9.41603 21.311L8.00003 19.874L6.58203 21.311L6.86503 19.349L4.81403 19.176L6.58303 18.165L5.44603 16.516L7.36903 17.218L8.00003 15.332Z" fill="white" />
                        <path d="M24.143 16.535L24.392 17.281L25.152 17.004L24.7 17.656L25.401 18.056L24.591 18.123L24.702 18.899L24.143 18.332L23.583 18.899L23.693 18.123L22.883 18.056L23.583 17.656L23.132 17.004L23.892 17.281L24.143 16.535Z" fill="white" />
                        <path d="M19.622 9.64499L19.871 10.391L20.631 10.113L20.18 10.766L20.881 11.165L20.07 11.233L20.182 12.009L19.622 11.441L19.061 12.009L19.172 11.233L18.362 11.165L19.063 10.766L18.61 10.113L19.372 10.391L19.622 9.64499Z" fill="white" />
                        <path d="M24.143 4.931L24.392 5.676L25.152 5.398L24.7 6.051L25.401 6.451L24.591 6.519L24.702 7.294L24.143 6.727L23.583 7.294L23.693 6.519L22.883 6.451L23.583 6.051L23.132 5.398L23.892 5.676L24.143 4.931Z" fill="white" />
                        <path d="M28.16 8.48401L28.41 9.23001L29.169 8.95301L28.722 9.60501L29.42 10.004L28.609 10.073L28.723 10.848L28.16 10.281L27.601 10.848L27.71 10.073L26.9 10.004L27.601 9.60501L27.149 8.95301L27.913 9.23001L28.16 8.48401Z" fill="white" />
                        <path d="M25.951 11.977L26.146 12.441L26.667 12.479L26.27 12.802L26.394 13.288L25.951 13.024L25.507 13.288L25.632 12.802L25.233 12.479L25.754 12.441L25.951 11.977Z" fill="white" />
                        <path d="M6.666 0H9.333V4H16V8H9.334V12H6.667V8H0V4H6.666V0Z" fill="white" />
                        <path d="M0 0H1.79L16 10.658V12H14.21L0 1.342V0Z" fill="white" />
                        <path d="M16 0H14.21L0 10.658V12H1.79L16 1.342V0Z" fill="white" />
                        <path d="M7.2 0H8.8V4.8H16V7.2H8.8V12H7.2V7.2H0V4.8H7.2V0Z" fill="#EC2727" />
                        <path d="M0 0L5.334 4H4.141L0 0.894V0ZM16 0H14.808L9.474 4H10.667L16 0Z" fill="#EC2727" />
                        <path d="M16 12L10.666 8H11.859L16 11.106V12ZM0 12H1.192L6.526 8H5.334L0 12Z" fill="#EC2727" />
                    </g>
                    <defs>
                        <clipPath id="clip0_152_3722">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            'title': 'FRA',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_152_3736)">
                        <path d="M11.833 0H0V24H11.833V0Z" fill="#182A53" />
                        <path d="M21.666 0H11.366V24H21.666V0Z" fill="white" />
                        <path d="M32 0H21.666V24H32V0Z" fill="#CF2029" />
                    </g>
                    <defs>
                        <clipPath id="clip0_152_3736">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            'title': 'RSA',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_153_3740)">
                        <path d="M32.006 -0.048996H-0.129028V24.08H32.006V-0.048996Z" fill="white" />
                        <path d="M3.78899 24.074C2.49099 24.074 1.19299 24.074 -0.104012 24.074C-0.142012 23.996 -0.124012 23.912 -0.124012 23.83C-0.125012 22.98 -0.125012 22.129 -0.125012 21.277C-0.134012 21.109 -0.0330124 21.023 0.0749876 20.941C0.680988 20.486 1.28799 20.031 1.89399 19.576C5.15999 17.125 8.42399 14.67 11.689 12.218C11.945 12.026 11.945 12.025 11.69 11.834C7.84799 8.946 4.00499 6.05901 0.160988 3.172C0.0419876 3.083 -0.105012 3.016 -0.125012 2.82C-0.125012 1.938 -0.120012 1.056 -0.129012 0.175005C-0.130012 -0.00499505 -0.0840124 -0.049995 0.0729876 -0.048995C1.24299 -0.041995 2.41299 -0.042995 3.58399 -0.047995C3.78699 -0.047995 3.95999 0.016005 4.12699 0.141005C6.11699 1.638 8.10199 3.141 10.1 4.624C12.193 6.178 14.272 7.75201 16.358 9.31701C16.538 9.45301 16.719 9.51501 16.936 9.51501C21.827 9.51001 26.715 9.511 31.604 9.513C31.741 9.513 31.889 9.468 32.001 9.605C32.001 11.218 32.001 12.83 32.001 14.441C31.943 14.515 31.867 14.535 31.782 14.535C31.689 14.535 31.595 14.535 31.502 14.535C26.654 14.535 21.809 14.537 16.96 14.533C16.731 14.533 16.536 14.592 16.344 14.738C14.442 16.177 12.538 17.615 10.625 19.035C8.46699 20.639 6.31999 22.264 4.16799 23.877C4.04899 23.971 3.91399 24.008 3.78899 24.074Z" fill="#0C7D5B" />
                        <path d="M32.002 -0.0229944C32.002 2.66001 32.002 5.34201 32.002 8.02501C31.95 8.09101 31.884 8.11801 31.807 8.11801C31.739 8.11801 31.671 8.12001 31.606 8.12001C26.871 8.12001 22.135 8.11901 17.401 8.12401C17.143 8.12401 16.924 8.05401 16.709 7.89201C13.349 5.35801 9.98203 2.83601 6.61903 0.307006C6.53403 0.244006 6.44203 0.193006 6.38503 0.0960056C6.33603 0.0150056 6.33703 -0.0459944 6.44703 -0.0449944C6.49403 -0.0439944 6.54003 -0.0449944 6.58803 -0.0449944C14.987 -0.0449944 23.387 -0.0449944 31.786 -0.0439944C31.857 -0.0439944 31.934 -0.0659944 32.002 -0.0229944Z" fill="#E1402A" />
                        <path d="M32.002 16.027C32.002 18.648 32.001 21.271 32.006 23.894C32.007 24.047 31.981 24.08 31.842 24.08C23.348 24.074 14.852 24.074 6.35701 24.074C6.34901 23.892 6.49101 23.845 6.59001 23.769C7.47201 23.103 8.35601 22.441 9.24001 21.777C11.747 19.894 14.253 18.011 16.759 16.127C16.934 15.996 17.11 15.93 17.321 15.93C22.108 15.934 26.895 15.934 31.684 15.934C31.796 15.934 31.915 15.914 32.002 16.027Z" fill="#2B3680" />
                        <path d="M-0.0980225 21.166C-0.0980225 20.523 -0.0980225 19.879 -0.0980225 19.234C-0.0520225 19.097 0.0529775 19.029 0.152978 18.955C3.16898 16.691 6.18498 14.428 9.19798 12.16C9.25298 12.119 9.31698 12.09 9.35798 12.027C9.31598 11.963 9.25298 11.934 9.19798 11.893C6.18498 9.62499 3.16898 7.36199 0.152978 5.09899C0.0529775 5.02399 -0.0520225 4.95499 -0.0980225 4.81799C-0.0980225 4.17299 -0.0980225 3.52899 -0.0980225 2.88499C1.68198 4.21899 3.46198 5.55199 5.24098 6.88599C7.45398 8.54599 9.66598 10.207 11.878 11.868C11.94 11.914 11.999 11.961 12.057 12.007C12.043 12.086 11.987 12.103 11.946 12.133C7.95498 15.131 3.96298 18.127 -0.0280225 21.121C-0.0510225 21.139 -0.0740225 21.152 -0.0980225 21.166Z" fill="#F9B418" />
                        <path d="M-0.0979805 4.81799C1.37502 5.92099 2.85002 7.02299 4.32302 8.12799C5.98602 9.37399 7.64702 10.622 9.30702 11.87C9.36802 11.915 9.42702 11.963 9.48502 12.008C9.47602 12.079 9.42502 12.094 9.38902 12.121C6.25002 14.478 3.11002 16.834 -0.0299805 19.191C-0.0519805 19.208 -0.0759805 19.22 -0.0989805 19.234C-0.10098 19.173 -0.10498 19.116 -0.10498 19.052C-0.10498 14.368 -0.10498 9.68199 -0.10498 4.99699C-0.10398 4.93799 -0.0999805 4.87799 -0.0979805 4.81799Z" fill="#020202" />
                    </g>
                    <defs>
                        <clipPath id="clip0_153_3740">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            'title': 'IRE',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_153_3747)">
                        <path d="M32 0H0V24H32V0Z" fill="#019E60" />
                        <path d="M26.75 0H10.292V24H26.75V0Z" fill="white" />
                        <path d="M32 0H22V24H32V0Z" fill="#F58020" />
                    </g>
                    <defs>
                        <clipPath id="clip0_153_3747">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
        {
            'title': 'USA',
            'svg':
                <svg className='w-[20px] block m-auto' width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_153_3752)">
                        <path d="M32 0H0V24H32V0Z" fill="#EB292A" />
                        <path d="M32 1.84599H0V3.69199H32V1.84599Z" fill="white" />
                        <path d="M32 5.53799H0V7.38499H32V5.53799Z" fill="white" />
                        <path d="M32 9.23H0V11.076H32V9.23Z" fill="white" />
                        <path d="M32 12.922H0V14.769H32V12.922Z" fill="white" />
                        <path d="M32 16.614H0V18.461H32V16.614Z" fill="white" />
                        <path d="M32 20.307H0V22.154H32V20.307Z" fill="white" />
                        <path d="M14.625 0H0V11.438H14.625V0Z" fill="#223165" />
                        <path d="M1.19801 0.492996L1.54301 1.677L0.640015 0.944996H1.75601L0.853015 1.677L1.19801 0.492996Z" fill="white" />
                        <path d="M3.59401 0.492996L3.93801 1.677L3.03601 0.944996H4.15201L3.24901 1.677L3.59401 0.492996Z" fill="white" />
                        <path d="M5.99001 0.492996L6.33401 1.677L5.43201 0.944996H6.54701L5.64401 1.677L5.99001 0.492996Z" fill="white" />
                        <path d="M8.38499 0.492996L8.72999 1.677L7.82599 0.944996H8.94299L8.03999 1.677L8.38499 0.492996Z" fill="white" />
                        <path d="M10.781 0.492996L11.125 1.677L10.222 0.944996H11.339L10.436 1.677L10.781 0.492996Z" fill="white" />
                        <path d="M13.176 0.492996L13.52 1.677L12.618 0.944996H13.734L12.831 1.677L13.176 0.492996Z" fill="white" />
                        <path d="M2.39601 1.64L2.74101 2.823L1.83801 2.091H2.95501L2.05101 2.823L2.39601 1.64Z" fill="white" />
                        <path d="M4.79197 1.64L5.13697 2.823L4.23297 2.091H5.34997L4.44597 2.823L4.79197 1.64Z" fill="white" />
                        <path d="M7.18703 1.64L7.53203 2.823L6.62903 2.091H7.74603L6.84203 2.823L7.18703 1.64Z" fill="white" />
                        <path d="M9.58302 1.64L9.92702 2.823L9.02502 2.091H10.141L9.23703 2.823L9.58302 1.64Z" fill="white" />
                        <path d="M11.979 1.64L12.324 2.823L11.42 2.091H12.537L11.633 2.823L11.979 1.64Z" fill="white" />
                        <path d="M1.19801 2.78799L1.54301 3.96999L0.640015 3.23899H1.75601L0.853015 3.96999L1.19801 2.78799Z" fill="white" />
                        <path d="M3.59401 2.78799L3.93801 3.96999L3.03601 3.23899H4.15201L3.24901 3.96999L3.59401 2.78799Z" fill="white" />
                        <path d="M5.99001 2.78799L6.33401 3.96999L5.43201 3.23899H6.54701L5.64401 3.96999L5.99001 2.78799Z" fill="white" />
                        <path d="M8.38499 2.78799L8.72999 3.96999L7.82599 3.23899H8.94299L8.03999 3.96999L8.38499 2.78799Z" fill="white" />
                        <path d="M10.781 2.78799L11.125 3.96999L10.222 3.23899H11.339L10.436 3.96999L10.781 2.78799Z" fill="white" />
                        <path d="M13.176 2.78799L13.52 3.96999L12.618 3.23899H13.734L12.831 3.96999L13.176 2.78799Z" fill="white" />
                        <path d="M2.39601 3.935L2.74101 5.118L1.83801 4.386H2.95501L2.05101 5.118L2.39601 3.935Z" fill="white" />
                        <path d="M4.79197 3.935L5.13697 5.118L4.23297 4.386H5.34997L4.44597 5.118L4.79197 3.935Z" fill="white" />
                        <path d="M7.18703 3.935L7.53203 5.118L6.62903 4.386H7.74603L6.84203 5.118L7.18703 3.935Z" fill="white" />
                        <path d="M9.58302 3.935L9.92702 5.118L9.02502 4.386H10.141L9.23703 5.118L9.58302 3.935Z" fill="white" />
                        <path d="M11.979 3.935L12.324 5.118L11.42 4.386H12.537L11.633 5.118L11.979 3.935Z" fill="white" />
                        <path d="M1.19801 5.082L1.54301 6.264L0.640015 5.534H1.75601L0.853015 6.264L1.19801 5.082Z" fill="white" />
                        <path d="M3.59401 5.082L3.93801 6.264L3.03601 5.534H4.15201L3.24901 6.264L3.59401 5.082Z" fill="white" />
                        <path d="M5.99001 5.082L6.33401 6.264L5.43201 5.534H6.54701L5.64401 6.264L5.99001 5.082Z" fill="white" />
                        <path d="M8.38499 5.082L8.72999 6.264L7.82599 5.534H8.94299L8.03999 6.264L8.38499 5.082Z" fill="white" />
                        <path d="M10.781 5.082L11.125 6.264L10.222 5.534H11.339L10.436 6.264L10.781 5.082Z" fill="white" />
                        <path d="M13.176 5.082L13.52 6.264L12.618 5.534H13.734L12.831 6.264L13.176 5.082Z" fill="white" />
                        <path d="M2.39601 6.229L2.74101 7.412L1.83801 6.68H2.95501L2.05101 7.412L2.39601 6.229Z" fill="white" />
                        <path d="M4.79197 6.229L5.13697 7.412L4.23297 6.68H5.34997L4.44597 7.412L4.79197 6.229Z" fill="white" />
                        <path d="M7.18703 6.229L7.53203 7.412L6.62903 6.68H7.74603L6.84203 7.412L7.18703 6.229Z" fill="white" />
                        <path d="M9.58302 6.229L9.92702 7.412L9.02502 6.68H10.141L9.23703 7.412L9.58302 6.229Z" fill="white" />
                        <path d="M11.979 6.229L12.324 7.412L11.42 6.68H12.537L11.633 7.412L11.979 6.229Z" fill="white" />
                        <path d="M1.19801 7.37601L1.54301 8.55801L0.640015 7.82701H1.75601L0.853015 8.55801L1.19801 7.37601Z" fill="white" />
                        <path d="M3.59401 7.37601L3.93801 8.55801L3.03601 7.82701H4.15201L3.24901 8.55801L3.59401 7.37601Z" fill="white" />
                        <path d="M5.99001 7.37601L6.33401 8.55801L5.43201 7.82701H6.54701L5.64401 8.55801L5.99001 7.37601Z" fill="white" />
                        <path d="M8.38499 7.37601L8.72999 8.55801L7.82599 7.82701H8.94299L8.03999 8.55801L8.38499 7.37601Z" fill="white" />
                        <path d="M10.781 7.37601L11.125 8.55801L10.222 7.82701H11.339L10.436 8.55801L10.781 7.37601Z" fill="white" />
                        <path d="M13.176 7.37601L13.52 8.55801L12.618 7.82701H13.734L12.831 8.55801L13.176 7.37601Z" fill="white" />
                        <path d="M2.39601 8.522L2.74101 9.706L1.83801 8.975H2.95501L2.05101 9.706L2.39601 8.522Z" fill="white" />
                        <path d="M4.79197 8.522L5.13697 9.706L4.23297 8.975H5.34997L4.44597 9.706L4.79197 8.522Z" fill="white" />
                        <path d="M7.18703 8.522L7.53203 9.706L6.62903 8.975H7.74603L6.84203 9.706L7.18703 8.522Z" fill="white" />
                        <path d="M9.58302 8.522L9.92702 9.706L9.02502 8.975H10.141L9.23703 9.706L9.58302 8.522Z" fill="white" />
                        <path d="M11.979 8.522L12.324 9.706L11.42 8.975H12.537L11.633 9.706L11.979 8.522Z" fill="white" />
                        <path d="M1.19801 9.67L1.54301 10.853L0.640015 10.122H1.75601L0.853015 10.853L1.19801 9.67Z" fill="white" />
                        <path d="M3.59401 9.67L3.93801 10.853L3.03601 10.122H4.15201L3.24901 10.853L3.59401 9.67Z" fill="white" />
                        <path d="M5.99001 9.67L6.33401 10.853L5.43201 10.122H6.54701L5.64401 10.853L5.99001 9.67Z" fill="white" />
                        <path d="M8.38499 9.67L8.72999 10.853L7.82599 10.122H8.94299L8.03999 10.853L8.38499 9.67Z" fill="white" />
                        <path d="M10.781 9.67L11.125 10.853L10.222 10.122H11.339L10.436 10.853L10.781 9.67Z" fill="white" />
                        <path d="M13.176 9.67L13.52 10.853L12.618 10.122H13.734L12.831 10.853L13.176 9.67Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_153_3752">
                            <rect width="32" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
        },
    ]

    const eventList = [
        {
            'title': 'TESTING 3',
            'eventTime': [
                '5:00'
            ],
            'country': 'IN'
        },
        {
            'title': 'TESTING 4',
            'eventTime': [
                '5:00'
            ],
            'country': 'IN'
        },
        {
            'title': 'Cheltenham ',
            'eventTime': [
                '23:25',
            ],
            'country': 'GB'
        },
        {
            'title': 'Newmarket',
            'eventTime': [
                '17:40', '18:15', '18:50', '19:25', '20:05', '20:40', '21:15', '21:45', '22:20'
            ],
            'country': 'GB'
        },
        {
            'title': 'Goodwood ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Uttoxeter ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Hexham ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '0:20', '0:55'
            ],
            'country': 'GB'
        },
        {
            'title': 'Thirsk ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Yarmouth  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'GB'
        },
        {
            'title': 'Toowoomba ',
            'eventTime': [
                '16:18'
            ],
            'country': 'AUS'
        },
        {
            'title': 'Bordeaux Le Bouscat ',
            'eventTime': [
                '16:17'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Auteuil ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Angers ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'FRA'
        },
        {
            'title': 'Greyville ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35'
            ],
            'country': 'RSA'
        },
        {
            'title': 'Turffontein ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'RSA'
        },
        {
            'title': 'Punchestown  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '23:00'
            ],
            'country': 'IRE'
        },
        {
            'title': 'Churchill Downs  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05', '18:35',
            ],
            'country': 'USA'
        },
        {
            'title': 'Laurel Park ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'USA'
        },
        {
            'title': 'Tampa Bay Downs',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:25',
            ],
            'country': 'USA'
        },
        {
            'title': 'Philadelphia ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25',
            ],
            'country': 'USA'
        },
        {
            'title': 'Belmont At The Big A ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05',
            ],
            'country': 'USA'
        },
        {
            'title': 'Gulfstream Park ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15', '18:05',
            ],
            'country': 'USA'
        },
        {
            'title': 'Woodbine  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35', '21:25', '22:25', '22:15',
            ],
            'country': 'USA'
        },
        {
            'title': 'Hawthorne  ',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25', '21:35',
            ],
            'country': 'USA'
        },
        {
            'title': 'Santa Anita Park',
            'eventTime': [
                '18:05', '18:35', '19:15', '19:50', '21:00', '20:25',
            ],
            'country': 'USA'
        },
    ]

    useEffect(() => (
        setEvents(eventList.filter(e => e.country == selectedCountry))
    ), [selectedCountry])
    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 '>
                <div className='col-span-12 bg-[#fff]  h-[fit-content]'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12'>
                            <div className='bg-[linear-gradient(-180deg,_#010101_0%,_#0e0e0e_100%)] text-[#ffffff] px-[8px] py-[10px]'>
                                <h5 className='p-0 m-0 text-[0.813rem] leading-[1.2] text-[#ffffff] font-bold'>Horse Racing Schedule</h5>
                            </div>
                        </div>
                        <div className='col-span-12'>
                            <ul className='bg-[#e2e2e2] border-b-[unset] [scrollbar-width:none] overflow-x-auto relative flex flex-nowrap pl-0 mb-0 [list-style:none] h-[45px] rounded-none'>
                                {
                                    countries.map((country, index) => (
                                        <li key={index}>
                                            <button type='button' className={`text-[#000000] !rounded-tl-[unset] !rounded-tr-[unset]  rounded-none text-[0.75rem] px-[22px] py-[3px] cursor-pointer w-[70px] h-[45px] border-[1px] border-[solid] border-[transparent] !items-center !justify-center !flex-col !flex [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${selectedCountry === country.title && 'bg-[#ffffff] border-l-[1px] border-l-[#c8ced3] border-r-[1px] border-r-[#c8ced3]'}  `} onClick={() => setSelectedCountry(country.title)}>
                                                {country.svg}
                                                <span className='font-bold text-[.75rem] text-[#000000]'>{country.title}</span>
                                            </button>
                                        </li>
                                    ))
                                }

                            </ul>
                            {selectedCountry === 'ALL' && (
                                <div className='mt-[-1px] !bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)] text-[#2789ce] border-[1px] border-[solid] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <table id="gameContainer" className='w-full mb-4 text-[#212529] align-top border-[#dee2e6] [caption-side:bottom] border-collapse'>
                                            <tbody className='text-[#212529]'>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 3</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px] text-[#7e97a7]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 4</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Cheltenham </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 03-05-2024 23:25 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Bordeaux Le Bouscat</span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Toowoomba </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 13:50 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 14:10 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            )}
                            {selectedCountry !== 'ALL' && (
                                <div className='mt-[-1px] bg-[#ffffff] border-[1px] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <div className=''>
                                            <ul className='m-0 p-0'>
                                                {events.map((event, index) => (
                                                    <li key={index} className='w-full pt-[10px] px-[0] pb-[5px] border-b-[1px] border-b-[#ccc] [list-style:none] inline-block'>
                                                        <div className='flex'>
                                                            <span className='text-[0.813rem] font-bold min-w-[110px] inline-block text-[#000000] '>{event.title}</span>
                                                            <ul className='inline-block ml-[10px] p-0 mb-0 mt-0'>
                                                                {event.eventTime.map((time, inex) => (
                                                                    <li key={index} className='[list-style:none] inline-block'>
                                                                        <Link to={'#'}>
                                                                            <span className='cursor-pointer bg-[#e2e2e2] px-[15px] py-[5px] rounded-[2px] text-[.75rem] inline-table font-bold text-[#000000] min-w-[64px] ml-[5px] mb-[5px]'>{time}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div >



            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative ' >
                <div className='col-span-12 bg-[#fff]  h-[fit-content]'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12'>
                            <div className='bg-[linear-gradient(-180deg,_#010101_0%,_#0e0e0e_100%)] text-[#ffffff] px-[8px] py-[10px]'>
                                <h5 className='p-0 m-0 text-[0.813rem] leading-[1.2] text-[#ffffff] font-bold'>Horse Racing Schedule</h5>
                            </div>
                        </div>
                        <div className='col-span-12'>
                            <ul className='bg-[#e2e2e2] border-b-[unset] [scrollbar-width:none] overflow-x-auto relative flex flex-nowrap pl-0 mb-0 [list-style:none] h-[45px] rounded-none'>
                                {
                                    countries.map((country, index) => (
                                        <li key={index}>
                                            <button type='button' className={`text-[#000000] !rounded-tl-[unset] !rounded-tr-[unset]  rounded-none text-[0.75rem] px-[22px] py-[3px] cursor-pointer w-[70px] h-[45px] border-[1px] border-[solid] border-[transparent] !items-center !justify-center !flex-col !flex [transition:color_.15s_ease-in-out,_background-color_.15s_ease-in-out,_border-color_.15s_ease-in-out] ${selectedCountry === country.title && 'bg-[#ffffff] border-l-[1px] border-l-[#c8ced3] border-r-[1px] border-r-[#c8ced3]'}  `} onClick={() => setSelectedCountry(country.title)}>
                                                {country.svg}
                                                <span className='font-bold text-[.75rem] text-[#000000]'>{country.title}</span>
                                            </button>
                                        </li>
                                    ))
                                }

                            </ul>
                            {selectedCountry === 'ALL' && (
                                <div className='mt-[-1px] !bg-[linear-gradient(-180deg,_#ffffff_0%,_#ffffff_100%)] text-[#2789ce] border-[1px] border-[solid] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <table id="gameContainer" className='w-full mb-4 text-[#212529] align-top border-[#dee2e6] [caption-side:bottom] border-collapse'>
                                            <tbody className='text-[#212529]'>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 3</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px] text-[#7e97a7]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>TESTING 4</span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 21-04-2024 05:00 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Cheltenham </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 03-05-2024 23:25 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Bordeaux Le Bouscat</span>
                                                        </Link>
                                                        <span className='!text-[12px] ml-[10px] font-bold in-play-effect'>In-Play</span>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]'>
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Toowoomba </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 13:50 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr className='border-b-[1px] border-b-[#c8ced3] border-[#c8ced3] text-[#212529]'>
                                                    <td className='pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] flex items-center flex-wrap bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] text-[#212529]'>
                                                        <Link to={'#'} className='text-[#7e97a7]' >
                                                            <span className='font-bold text-[.75rem] text-[#2789ce]'>Ascot </span>
                                                        </Link>
                                                        <p className='ml-[10px] mr-[0] my-[0] text-[#777777] font-normal text-[.75rem] inline'> 04-05-2024 14:10 </p>
                                                    </td>
                                                    <td className='w-[25px] pl-[6px] pr-[5px] py-[5px] border-[none] align-middle min-h-[44px] bg-transparent [box-shadow:inset_0_0_0_9999px_transparent] !pl-0'>
                                                        <button className='text-[#7e97a7]'>
                                                            <svg className='h-[20px]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                                <path fill="currentColor" d="M12.5 25C5.596 25 0 19.404 0 12.5S5.596 0 12.5 0 25 5.596 25 12.5 19.404 25 12.5 25zm0-1C18.85 24 24 18.85 24 12.5S18.85 1 12.5 1 1 6.15 1 12.5 6.15 24 12.5 24zm5.09-12.078c1.606.516 2.41 1.13 2.41 2.19 0 .373-.067.616-.2.73-.135.115-.403.173-.804.173H13.57l-.81 7.988h-.536l-.795-7.988H6.003c-.4 0-.67-.065-.803-.194-.133-.128-.2-.364-.2-.708 0-1.06.804-1.674 2.41-2.19.09 0 .18-.03.27-.086.49-.172.802-.444.936-.816L9.82 5.95v-.216c0-.23-.222-.415-.668-.558l-.067-.043h-.067c-.536-.143-.804-.387-.804-.73 0-.402.09-.652.268-.753.18-.1.49-.15.938-.15h6.16c.447 0 .76.05.938.15.178.1.268.35.268.752 0 .344-.268.588-.804.73h-.067l-.067.044c-.446.143-.67.33-.67.558v.215l1.206 5.07c.134.372.446.644.937.816.09.057.18.086.27.086z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            )}
                            {selectedCountry !== 'ALL' && (
                                <div className='mt-[-1px] bg-[#ffffff] border-[1px] border-[#c8ced3]'>
                                    <div className='p-[10px] text-[#2789ce]'>
                                        <div className=''>
                                            <ul className='m-0 p-0'>
                                                {events.map((event, index) => (
                                                    <li key={index} className='w-full pt-[10px] px-[0] pb-[5px] border-b-[1px] border-b-[#ccc] [list-style:none] inline-block'>
                                                        <div className='flex'>
                                                            <span className='text-[0.813rem] font-bold min-w-[110px] inline-block text-[#000000] '>{event.title}</span>
                                                            <ul className='inline-block ml-[10px] p-0 mb-0 mt-0'>
                                                                {event.eventTime.map((time, inex) => (
                                                                    <li key={index} className='[list-style:none] inline-block'>
                                                                        <Link to={'#'}>
                                                                            <span className='cursor-pointer bg-[#e2e2e2] px-[15px] py-[5px] rounded-[2px] text-[.75rem] inline-table font-bold text-[#000000] min-w-[64px] ml-[5px] mb-[5px]'>{time}</span>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default HorseRacingSchedule;
